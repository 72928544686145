import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MyApp from './live-app/MyApp';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// Optional: report web vitals (can be ignored if unused)
reportWebVitals();
